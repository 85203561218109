import * as React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import TopMenu from "../../components/menu"
import { Container, Row, Col } from "react-bootstrap"
import Footer from "../../components/footer"
import Style from "../common.css"
import Head from "../../components/header"

const PolicyPage = () => (
  <Layout>
    <Head pageTitle="ウェブサイト プライバシーポリシー - 株式会社GAZIRU" pageLang="ja" />
    <TopMenu />
    <Container>
      <Row>
        <Col sm={1}>
        </Col>
        <Col sm={10}>
      <h4>株式会社GAZIRU ウェブサイト プライバシーポリシー</h4>
      <p>株式会社GAZIRU（以下「当社」といいます。）は、本ウェブサイト上で提供するサービス（以下「本サービス」といいます。）における、ユーザーの個人情報を含むパーソナルデータの取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を定めます。</p>
      <h5>取得方法および取得する情報</h5>
      <h6>ユーザーから直接取得する情報</h6>
      <p>当社は、本サービスの利用にあたって、ユーザーからのメールでの問い合わせ等を通じて、直接、以下の情報を取得します。</p>
      <ul>
        <li>氏名</li>
        <li>生年月日</li>
        <li>性別</li>
        <li>メールアドレス</li>
        <li>住所</li>
        <li>その他当社が取得すると定めた情報</li>
      </ul>

      <h6>技術情報</h6>
      <p>当社は、ユーザーが本サービスにアクセスする際に、以下の技術情報を取得します。</p>
      <ul>
        <li>ブラウザの種類</li>
        <li>端末の種類</li>
        <li>リファラ</li>
        <li>端末識別子</li>
        <li>IPアドレス</li>
        <li>Cookie</li>
        <li>その他アクセスの分析・スパム検出に役立てるために取得が必要な情報</li>
      </ul>
      <h6>外部サービスとの連携により取得する情報</h6>
      <p>当社は、ユーザーが本サービス上で外部サービスとの連携を許可した場合、以下の情報を当該外部サービスから取得します。</p>
      <ul>
        <li>外部サービスでユーザーが利用するID</li>
        <li>外部サービスのプライバシー設定によりユーザーが連携先に開示することを認めたその他の情報</li>
      </ul>

      <h5>利用の目的</h5>
      <p>当社は、取得したユーザー情報を、以下の目的のために使用します。</p>
      <ul>
        <li>本サービスおよび当社が提供する各種サービス・商品の提供</li>
        <li>本サービスおよび当社が提供する各種サービス・商品に関する重要な通知の実施</li>
        <li>本サービスおよび当社が提供する各種サービス・商品に関するご案内・お問い合わせへの対応</li>
        <li>本人確認・ユーザー認証</li>
        <li>ユーザーのトラフィック測定および行動測定</li>
        <li>広告の配信およびその成果確認</li>
        <li>その他当社が定める目的</li>
      </ul>

      <h5>第三者への提供</h5>
      <p>当社は、次の場合を除き、あらかじめユーザーの同意を得ることなく第三者に個人情報を提供することはありません。</p>
      <ul>
      <li>個人情報保護法その他の法令で認められる場合</li>
      <li>人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合</li>
      <li>公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難である場合</li>
      <li>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
      </ul>

      <h5>個人情報の開示</h5>
      <p>当社は、ユーザーから個人情報の開示を求められたときは、本人からの請求であることを確認の上、遅滞なくこれを開示します。ただし、以下の場合はこの限りではありません。</p>
      <ul>

      <li>個人情報保護法その他の法令により、当社が開示の義務を負わない場合</li>
      <li>開示することにより、本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</li>
      <li>当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
      <li>その他法令に違反することとなる場合</li>
      </ul>
      <p>なお、個人情報の開示の申し出先、手続き、手数料につきましては別途定めます。</p>

      <h5>個人情報の訂正</h5>
      <p>当社は、ユーザーから、自己の個人情報が誤った情報であるという理由で、当社が定める手続きにより個人情報の訂正、追加または削除（以下、「訂正等」といいます。）を求められた場合には、本人からの請求であることを確認の上、遅滞なく調査し、その結果に基づき当該個人情報の内容の訂正等を行い、その旨をユーザーに通知します。</p>
      <p>ただし、個人情報保護法その他の法令により、当社が訂正等の義務を負わない場合は除きます。</p>

      <h5>個人情報の利用の停止</h5>

      <p>当社は、本人から、以下の各号に示す理由に基づき、個人情報の利用の停止または消去（以下、「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行い、その結果に基づき個人情報の利用停止等を行い、その旨をユーザーに通知します。</p>

      <ol>
      <li>個人情報が、あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由</li>
      <li>個人情報が、偽りその他不正な手段により取得されたものであるという理由</li>
      </ol>

      <p>ただし、個人情報保護法その他の法令により、当社が利用停止等の義務を負わない場合は除きます。</p>

      <h5>本ポリシーの変更</h5>

      <p>当社は、収集する対象となる個人情報およびすでに収集した個人情報の利用目的を含めて、本ポリシーを、ユーザーに事前に通知することなく当社の裁量に基づいて変更することができるものとします。</p>
      <p>本ポリシーの変更を行った場合には、変更後の本ポリシーの施行時期および内容を当社所定の方法により周知します。</p>

      <h5>お問い合わせ</h5>
      <p>本ポリシーに関するお問い合わせは、本サービスで提供しているお問い合わせフォームよりお問い合わせください。</p>

      <p><Link className="text-decoration-none" to="/">Home</Link></p>


        </Col>
        <Col sm={1}>
        </Col>
      </Row>
    </Container>
    <Footer />
  </Layout>
)

export default PolicyPage
