import { useLocation } from "@reach/router"
import { Link, navigate } from "gatsby"
import * as React from "react"
import { Button, Nav, Navbar } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import LOGO from '../images/logo_s.png'
import * as Style from './menu.module.css'

const LOGO_ALT = "GAZIRU Logo";
const LOGO_WIDTH = 188;
const LOGO_HEIGHT = 36;

// Menu

function Menu() {
  const { t, i18n } = useTranslation()

  const locale = i18n.language
  let text = locale === 'en' ? 'Japanese' : 'English'

  const location = useLocation()

  const changeLang = () => {
    const element = document.getElementById("language-btn")
    const localeFrom = element.textContent === 'Japanese' ? 'en' : 'ja'
    const localeTo = element.textContent === 'English' ? 'en' : 'ja'

    text = locale === 'en' ? 'Japanese' : 'English'

    if (location) {
      const toPath = location.pathname.replace(localeFrom, localeTo);
      i18n.changeLanguage(localeTo);
      localStorage.setItem('lang', localeTo);
      element.textContent = text
      navigate(toPath);
    }
  }
  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top" className={Style.menu}>
        <Navbar.Brand as={Link} to="/#top">
          <img src={LOGO} width={LOGO_WIDTH} height={LOGO_HEIGHT} alt={LOGO_ALT} className={Style.logo} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className={Style.collapse}>
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/#top" className={Style.content}>Home</Nav.Link>
            <Nav.Link as={Link} to={t('key7')} className={Style.content}>{t('key2')}</Nav.Link>
            <Nav.Link as={Link} to={t('key8')} className={Style.content}>{t('key3')}</Nav.Link>
            <Nav.Link as={Link} to={t('key9')} className={Style.content}>{t('key4')}</Nav.Link>
            <Nav.Link as={Link} to={t('key10')} className={Style.content}>{t('key5')}</Nav.Link>
            <Nav.Link as={Link} to={t('key11')} className={Style.content}>{t('key6')}</Nav.Link>
            <Nav.Link as={Link} to={t('key120')} className={Style.content}>{t('key119')}</Nav.Link>
            <Nav.Link id="language-btn" as={Button} variant="link" className={Style.content} onClick={() => changeLang()}>{text}</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar >
    </>
  )
}

export default Menu